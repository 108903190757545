import React, { useState, useEffect } from 'react'
import TransitionContext from '@context/transitioncontext'
import { useAllDailiesMedia } from '@queries/ALL_DAILIES_MEDIA_QUERY'
import DailiesGallery from '@components/drilldown/dailiesgallery'
import { chunk, sum } from 'lodash'
import { Box, Flex } from 'rebass/styled-components'
import { rhythm } from '@utils/typography'
import SEO from '@components/seo'

const Dailies = props => {
  const { location } = props
  // Get queries for Dailies Page
  const data = useAllDailiesMedia()
  const { dailiesImages, dailiesVideos } = data

  // Map nodes for images and gifs seperately
  const images = dailiesImages.edges.map(edge => ({
    ...edge.node.childImageSharp.fluid,
  }))
  const gifs = dailiesVideos.edges.map(edge => ({ ...edge.node }))

  // empty state for gallery items
  const [galleryItems, setGalleryItems] = useState([])
  const itemsPerRowByBreakpoints = [1, 2, 3, 3]
  // create aspect ratio field for gifs based on filename and map into galleryItems state

  const gifsWithAspects = gifs.map(gif => {
    const filename = gif.name
    const match = filename.match(/\((.*?)\)/)

    const integer = Number(match[1])

    return { ...gif, aspectRatio: integer }
  })

  const newImages = [...images, ...gifsWithAspects]

  useEffect(() => {
    const temp = newImages.slice()

    // eslint-disable-next-line no-plusplus
    for (let i = temp.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[temp[i], temp[j]] = [temp[j], temp[i]]
    }
    setGalleryItems(temp)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const aspectRatios = galleryItems.map(image => image.aspectRatio)

  const rowAspectRatioSumsByBreakpoints = itemsPerRowByBreakpoints.map(
    itemsPerRow =>
      chunk(aspectRatios, itemsPerRow).map(rowAspectRatios =>
        sum(rowAspectRatios)
      )
  )

  return (
    <TransitionContext location={location}>
      <SEO title="Dailies" pathname={location.pathname} />
      <Flex
        flexDirection="column"
        px={`${rhythm(2)}`}
        py={`${rhythm(3)}`}
        maxWidth="1200px"
        m="0 auto"
        alignItems="center"
      >
        <Box width={1}>
          <h1>DAILIES</h1>
          <p>
            Experiments, explorations, and other random junk from daily
            practices. This stuff allows me to explore new techniques, learn new
            tools and inform future projects. Everything from 3D, 2D,
            simulations, to web experiments, and back again.{' '}
          </p>
        </Box>
        <DailiesGallery
          galleryItems={galleryItems}
          rowAspectRatioSumsByBreakpoints={rowAspectRatioSumsByBreakpoints}
          itemsPerRow={itemsPerRowByBreakpoints}
        />
      </Flex>
    </TransitionContext>
  )
}

export default Dailies
