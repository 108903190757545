import Img from 'gatsby-image'
import React, { useEffect } from 'react'
import { Box } from 'rebass/styled-components'
import { useInView } from 'react-intersection-observer'
import { AnimatePresence, motion } from 'framer-motion'

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      duration: 1,
      delay: 0.5,
    },
  },
}

const DailiesGallery = ({
  galleryItems,
  rowAspectRatioSumsByBreakpoints,
  itemsPerRow: itemsPerRowByBreakpoints,
}) => {
  const items = galleryItems.map((image, i) => {
    let galleryItem
    if (image.publicURL) {
      galleryItem = (
        <GifVideo
          alt={image.name}
          rowAspectRatioSumsByBreakpoints={rowAspectRatioSumsByBreakpoints}
          i={i}
          image={image}
          itemsPerRowByBreakpoints={itemsPerRowByBreakpoints}
          key={image.id}
          src={image.publicURL}
        />
      )
    } else {
      galleryItem = (
        <Box
          as={Img}
          alt={image.originalName}
          title={image.originalName}
          key={image.src}
          fluid={image}
          width={rowAspectRatioSumsByBreakpoints.map(
            (rowAspectRatioSums, j) => {
              const rowIndex = Math.floor(i / itemsPerRowByBreakpoints[j])
              const rowAspectRatioSum = rowAspectRatioSums[rowIndex]

              return `${(image.aspectRatio / rowAspectRatioSum) * 100}%`
            }
          )}
          css={{ display: 'inline-block' }}
        />
      )
    }

    return galleryItem
  })
  return (
    <AnimatePresence>
      <motion.div
        variants={container}
        initial="hidden"
        animate="show"
        exit="hidden"
      >
        {items}
      </motion.div>
    </AnimatePresence>
  )
}
export const GifVideo = ({
  image,
  alt,
  i,
  src,
  rowAspectRatioSumsByBreakpoints,
  itemsPerRowByBreakpoints,
  width,
}) => {
  const [ref, inView] = useInView({ threshold: 0.89 })

  useEffect(() => {
    if (inView) {
      ref.current?.play()
    } else {
      ref.current?.pause()
    }
  }, [ref, inView])

  return (
    <Box
      as="video"
      css={{ display: 'inline-block' }}
      ref={ref}
      autoPlay
      playsInline
      muted
      loop
      alt={alt}
      type="video/mp4"
      width={
        width ||
        rowAspectRatioSumsByBreakpoints.map(
          // Return a value for each breakpoint
          (rowAspectRatioSums, j) => {
            // Find out which row the image is in and get its aspect ratio sum
            const rowIndex = Math.floor(i / itemsPerRowByBreakpoints[j])
            const rowAspectRatioSum = rowAspectRatioSums[rowIndex]

            return `${(image.aspectRatio / rowAspectRatioSum) * 100}%`
          }
        )
      }
      src={src}
    />
  )
}

export default DailiesGallery
