import { graphql, useStaticQuery } from 'gatsby'

export const useAllDailiesMedia = () => {
  const data = useStaticQuery(graphql`
    {
      dailiesImages: allFile(
        filter: { sourceInstanceName: { eq: "socialimages" } }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 500, quality: 90) {
                aspectRatio
                originalName
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      dailiesSEO: file(
        absolutePath: {
          eq: "/Users/ahoagmedia/Documents/GitHub/ahmgatsby/src/socialimages/CityRenderB3D.jpg"
        }
      ) {
        id
        childImageSharp {
          resize(width: 1024, height: 1024, cropFocus: CENTER) {
            src
            width
            height
          }
        }
      }

      dailiesVideos: allFile(
        filter: { sourceInstanceName: { eq: "socialvideos" } }
      ) {
        edges {
          node {
            id
            name
            publicURL
          }
        }
      }
    }
  `)
  return data
}
